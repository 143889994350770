/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cursor-pointer{
    cursor: pointer;
}

table {
    width: 100%;
}

th {
    width: 15%;
  }

  .icon-color {
    color: #1878b0;
  }


.position-right{
  float: right;
}

.padding-left {
  padding-left: 20px;
}

.text-font-12{
  font-size: 12px !important;
}

.spinner {
  margin: 0 auto ;
}
